import request from '@/utils/request'

const userApi = {
  tableList: '/api/shutdown_cause',
  stopList: '/api/shutdown_total',
  dayApqOee:'/api/day_apq_oee',//丢弃
  apqOee:'/api/apq_oee'//综合效率 
}


// 表格数据
export function reqShutdownCause(parameter) {
  return request({
    url: userApi.tableList,
    method: 'post',
    data: parameter
  })
}
//获取停机时长
export function reqStopData(parameter) {
  return request({
    url: userApi.stopList,
    method: 'post',
    data: parameter
  })
}

// 天-设备综合效率---2024-03-13弃用
export function reqDayApqOee(parameter) {
  return request({
    url: userApi.dayApqOee,
    method: 'post',
    data: parameter
  })
}
export function reqApqOee(parameter) {
  return request({
    url: userApi.apqOee,
    method: 'post',
    data: parameter
  })
}